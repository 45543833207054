import React, { useCallback, useEffect, useRef, useState } from 'react'
import MachineDetailSection from '../../components/MachineDetailSection'
import ColdIcon from '../../assets/images/cold.png'
import WarmIcon from '../../assets/images/warm.png'
import HotIcon from '../../assets/images/hot.png'
import PromptpayIcon from '../../assets/images/prompt-pay.png'
import TruemoneyIcon from '../../assets/images/true-money.png'
import LinepayIcon from '../../assets/images/line-pay.png'
import { useParams } from 'react-router-dom'
import TextField from '../../components/TextField'
import { MdLocalPhone, MdClose, MdConfirmationNumber, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md'
import MachineNotIDLE from '../../components/MachineNotIDLE'
import MachineNotFound from '../../components/MachineNotFound'
import OceanWave from '../../components/OceanWave'
import useBranch from '../../hooks/useBranch'
import Button from '../../components/Button'
import useTransaction from '../../hooks/useTransaction'
import { Turnstile } from '@marsidev/react-turnstile'
import ModalPayment from '../../components/payments/ModalPayment'
import { useNavigate } from "react-router-dom"

const Page = () => {
  const navigate = useNavigate()
  const { branchid, machineid } = useParams()
  const { isLoading, getBranchAndMachine, getMachineStatus } = useBranch()
  const { createTransaction } = useTransaction()

  const [machineData, setMachineData] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [isNotFound, setNotFound] = useState(false)
  const [token, setToken] = useState(null)
  const [coupon, setCoupon] = useState('')
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const [useCoupon, setUseCoupon] = useState(false)
  const ref = useRef(null)
  const [provider, setProvider] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [transaction, setTransaction] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const [errorSelectedProgram, setErrorSelectedProgram] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const color = {
    COLD: 'bg-[#b9eaff]',
    WARM: 'bg-[#f9c3af]',
    HOT: 'bg-[#ff889c]'
  }

  const getWasherData = useCallback(
    async (branchid, machineid) => {
      const data = await getBranchAndMachine(branchid, machineid)
      // console.log('🚀 ~ getWasherData ~ data:', data)
      if (data?.branch && data?.machine) {
        setMachineData(data)

        if(data?.machine && data?.machine?.type !== 'WASHER') {
          setNotFound(!isNotFound)
        }

        const responseMachine = await getMachineStatus(branchid, machineid)

        const machineStatusList = responseMachine?.data || []

        const updateStatus = machineStatusList.find((item) => item.machine_id === machineid)

        if (updateStatus && updateStatus?.status !== 'DISCONNECTED') {
          setMachineData((prevState) => ({
            ...prevState,
            machine: {
              ...prevState?.machine,
              ...updateStatus
            }
          }))
        } else {
          setMachineData((prevState) => ({
            ...prevState,
            machine: {
              ...prevState?.machine,
              "machine_id": machineid,
              "status": "DISCONNECTED",
              "current_transaction": "",
              "temperature": 0,
              "remaining_minute": 0,
              "water_level": 0,
              "current_program": 0,
              "current_operation_step": 0,
              "coin_in_cash_box": 0,
              "remaining_second": 0,
              "version": 0,
              "updated_at": null
            }
          }))
        }

      } else {
        setNotFound(true)
      }
    },
    [getBranchAndMachine]
  )

  const getOptionImage = option => {
    if (option === 'WARM') {
      return (
        <>
          <img src={WarmIcon} width={10} alt="warm-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">น้ำอุ่น</span>
        </>
      )
    } else if (option === 'HOT') {
      return (
        <>
          <img src={HotIcon} width={10} alt="hot-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">น้ำร้อน</span>
        </>
      )
    } else if (option === 'COLD') {
      return (
        <>
          <img src={ColdIcon} width={10} alt="cold-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">น้ำเย็น</span>
        </>
      )
    }
  }

  useEffect(() => {
    if (branchid && machineid) {
      getWasherData(branchid, machineid)
    }
    return () => {
      setMachineData(null)
      setNotFound(false)
      setSelectedProgram(null)
      setRefresh(false)
    }
  }, [branchid, machineid])

  useEffect(() => {
    if (errorSelectedProgram) {
      setTimeout(() => {
        setErrorSelectedProgram(false)
      }, 4000)
    }

  }, [errorSelectedProgram])

  useEffect(() => {
    if (refresh) {
      navigate('/thank-you')
    }
  }, [refresh])

  const onChangeCoupon = e => {
    let mask = 'XXXX-XXXX'
    let maskChar = '-'
    const value = e.target.value
    let newValue = value

    if (value.endsWith(maskChar) && e.nativeEvent.inputType === 'deleteContentBackward') {
      newValue = value.slice(0, -1) // Remove the maskChar
    } else {
      if (mask[value.length] === maskChar) {
        newValue += maskChar
      }
    }

    setCoupon(newValue.toUpperCase())
  }

  const onChangeCustomerPhonenumber = e => {
    const result = e.target.value.replace(/\D/g, '')
    setCustomerPhoneNumber(result)
  }

  const onSetUseCoupon = () => {
    setUseCoupon(!useCoupon)
    setCoupon('')
  }

  const onCreateTransaction = async () => {
    setModalLoading(true)
    let body = {
      branch_id: machineData?.branch?._id,
      machine_id: machineData?.machine?._id,
      is_offline: false,
      program: selectedProgram?.program || null,
      coupon_code: coupon || null,
      customer_phone_number: customerPhoneNumber || '',
      transaction_type: 'EXPRESS'
    }

    try {
      const response = await createTransaction(body)
      if (response?._id) {
        setTransaction(response)
      }
      throw response
    } catch (err) {
      // console.log("🚀 ~ onShowPromptPayModal ~ err:", err)
    } finally {
      setModalLoading(false)
    }
  }

  const onShowModal = async (type) => {
    if (!selectedProgram) {
      setErrorSelectedProgram(true)
      return;
    }

    await onCreateTransaction()

    switch (type) {
      case 'PROMPTPAY':
        setProvider('promptpay')
        setShowPaymentModal(true)
        break
      case 'TRUEMONEY':
        setProvider('truemoney')
        setShowPaymentModal(true)
        break
      case 'LINEPAY':
        setProvider('linepay')
        setShowPaymentModal(true)
        break
      default:
        setProvider('')
        setShowPaymentModal(false)
        break
    }
  }

  return (
    <div className="relative max-w-4xl w-full h-[calc(100vh_-_160px)]">
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <span className="loading loading-ring loading-lg text-blue-500"></span>
        </div>
      ) : (
        <>
          {!isNotFound ? (
            <>
              {machineData?.machine?.status === 'IDLE' ? (
                <>
                  <MachineDetailSection machineType="WASH" machine={machineData} selectedProgram={selectedProgram} />

                  <div className="absolute z-10 w-full p-6 flex flex-col self-stretch gap-6">
                    <div className={`flex flex-col self-stretch gap-2 ${errorSelectedProgram ? 'p-2 animate-error-bg-pulse rounded-lg' : ''}`}>
                      <div className="text-zinc-800 text-sm font-medium">เลือกอุณหภูมิ</div>
                      <div className="flex gap-2 self-stretch items-center">
                        {machineData?.machine?.pricings &&
                          machineData?.machine?.pricings.map((item, index) => (
                            <div
                              className={`w-full cursor-pointer rounded-md py-2.5 px-3 border ${selectedProgram?.program === item.program ? color[item.program] : 'bg-gray-50'
                                } items-center justify-center gap-[10px] flex`}
                              key={`washing-machine-${item.program}`}
                              onClick={() => setSelectedProgram(item)}>
                              {getOptionImage(item.program)}
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เบอร์โทรศัพท์</div>
                      <TextField
                        prependIcon={<MdLocalPhone className="w-4 h-4 text-gray-500" />}
                        placeholder="เบอร์โทรศัพท์"
                        value={customerPhoneNumber}
                        onChange={e => onChangeCustomerPhonenumber(e)}
                        maxLength={10}
                        appendIcon={
                          <MdClose className="w-4 h-4 text-gray-500" onClick={() => setCustomerPhoneNumber('')} />
                        }
                      />
                    </div>

                    <div className="flex flex-col self-stretch gap-2">
                      <div className="w-fit">
                        <div className="text-zinc-800 text-sm font-medium flex gap-1 items-center cursor-pointer" onClick={() => onSetUseCoupon()}>
                          <MdConfirmationNumber className="w-4 h-4 text-gray-500" /> ใช้งานคูปองส่วนลด
                          <MdOutlineExpandLess className="w-4 h-4 text-gray-500" hidden={useCoupon} />
                          <MdOutlineExpandMore className="w-4 h-4 text-gray-500" hidden={!useCoupon} />
                        </div>
                      </div>
                      {useCoupon ? (
                        <div className="fade-in">
                          <div className="text-zinc-800 text-sm font-medium">รหัสคูปองส่วนลด</div>
                          <TextField
                            prependIcon={<MdConfirmationNumber className="w-4 h-4 text-gray-500" />}
                            placeholder="รหัสคูปองส่วนลด"
                            value={coupon}
                            onChange={e => onChangeCoupon(e)}
                            maxLength={9}
                            appendIcon={<MdClose className="w-4 h-4 text-gray-500" onClick={() => setCoupon('')} />}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {/* <Turnstile
                      ref={ref}
                      hidden={token}
                      className="my-2"
                      siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                      onSuccess={token => setToken(token)}
                    /> */}
                    {/* <div className="flex flex-col self-stretch gap-2" hidden={!token}> */}
                    
                    <div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เลือกช่องทางการชำระเงิน</div>
                      <div className="grid grid-cols-3 gap-2">
                        <div className="col-span-1">
                          <Button
                            className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                            label={<img src={PromptpayIcon} height={20} alt="promptpay-payment" />}
                            onClick={() => onShowModal('PROMPTPAY')}
                            disabled={modalLoading}
                            loading={modalLoading}
                          />
                        </div>
                        <div className="col-span-1">
                          <Button
                            className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                            label={<img src={TruemoneyIcon} height={20} alt="truemoney-payment" />}
                            onClick={() => onShowModal('TRUEMONEY')}
                            disabled={modalLoading}
                            loading={modalLoading}
                          />
                        </div>
                        <div className="col-span-1">
                          <Button
                            className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                            label={<img src={LinepayIcon} height={20} alt="linepay-payment" />}
                            onClick={() => onShowModal('LINEPAY')}
                            disabled={modalLoading}
                            loading={modalLoading}
                          />
                        </div>
                      </div>
                    </div>

                    <ModalPayment
                      resetRef={() => {
                        ref.current.reset()
                        setToken(null)
                      }}
                      token={token}
                      // coupon={coupon}
                      // customerPhoneNumber={customerPhoneNumber}
                      // customerPhoneNumberPoint={customerPhoneNumberPoint}
                      transaction={transaction}
                      selectedProgram={selectedProgram}
                      machineData={machineData}
                      showModal={showPaymentModal}
                      setShowModal={setShowPaymentModal}
                      provider={provider}
                      setRefresh={setRefresh}
                    />
                  </div>
                </>
              ) : (
                <MachineNotIDLE machineType="WASH" machine={machineData} />
              )}

              <OceanWave />
            </>
          ) : (
            <MachineNotFound machineType="WASH" />
          )}
        </>
      )}
    </div>
  )
}

export default Page
