import { useCallback, useEffect, useState, useRef } from 'react'
import ThaiQRPayment from '../../assets/images/thai-qr-payment.png'
import CodeCleanLogo from '../../assets/images/codeclean-logo.png'
import usePayment from '../../hooks/usePayment'
import QRCode from 'qrcode'
import QrPayImage from './QrPayImage'
import PaymentSuccess from '../../assets/images/payment-success.png'
import PaymentFailed from '../../assets/images/payment-failed.png'
import PriceDetail from './PriceDetail'
import VerifyButtonGroup from './VerifyButtonGroup'
import TrueMoneyQRPayment from '../../assets/images/true-money-qr-payment.png'
import LinePay from '../../assets/images/line-pay.png'
import { toPng } from 'html-to-image'
import html2canvas from 'html2canvas'

const ModalPayment = props => {
  const {
    resetRef,
    token,
    transaction,
    selectedProgram,
    machineData,
    showModal,
    setShowModal,
    setRefresh,
    incExtendDry,
    provider
  } = props
  const { generatePaymentQR, verifyTransaction, freeTransaction } = usePayment()

  const qrcodeRef = useRef(null)
  const [qrcodeData, setQrcodeData] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [imageLoading, setImageLoading] = useState(false)
  const [timeLeft, setTimeLeft] = useState(300)
  const [verify, setVerify] = useState(true)
  const [success, setSuccess] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [verifyRemainingTime, setVerifyRemainingTime] = useState(15)
  const [verifyDisabled, setVerifyDisabled] = useState(false)
  const [paymentChannel, setPaymentChannel] = useState('')

  const createQRCodeData = useCallback(async () => {
    setQrcodeData(null)
    setImageLoading(true)
    if (!transaction || (!incExtendDry && !selectedProgram) || !machineData || !showModal) {
      return
    }

    let body = {
      transaction_ref: transaction.transaction_ref,
      is_extend_dry: incExtendDry || false
    }

    try {
      const response = await generatePaymentQR(token, provider, body)
      if (response?.data?.raw_qr_data && response?.provider) {
        let data = response?.data
        data.qrImage = await QRCode.toDataURL(response?.data.raw_qr_data)
        setQrcodeData(data)
        setPaymentChannel(response.provider)
      }
      throw response
    } catch (err) {
      // console.log("🚀 ~ createQRCodeData ~ err:", err)
    } finally {
      setImageLoading(false)
    }
  }, [transaction, selectedProgram, machineData, showModal, token])

  useEffect(() => {
    if (transaction && ((!incExtendDry && selectedProgram) || incExtendDry) && machineData && showModal) {
      console.log(
        '🚀 ~ ModalPromptPay ~ transaction, selectedProgram, machineData, incExtendDry:',
        transaction,
        selectedProgram,
        machineData,
        incExtendDry
      )

      document.getElementById('payment_modal').classList.add('modal-open')
      calculateDiscout()
      remainingTime()
      if (calculateSumAmount() > 0) {
        createQRCodeData()
      } else {
        onVerifyTransaction()
      }
    } else {
      document.getElementById('payment_modal').classList.remove('modal-open')
    }

    return () => {
      setQrcodeData(null)
      setDiscountAmount(0)
      setTimeLeft(300)
      setSuccess(false)
      setVerify(false)
      setVerifyRemainingTime(15)
      setVerifyDisabled(false)
      setPaymentChannel('')
    }
  }, [showModal])

  useEffect(() => {
    if (showModal) {
      if (timeLeft < 1) {
        setShowModal(false)
      } else {
        const timer = setInterval(() => {
          setTimeLeft(prevTime => prevTime - 1)
        }, 1000)

        return () => clearInterval(timer)
      }
    }
  }, [showModal, setShowModal, timeLeft])

  const formatTime = time => {
    if (time > 0) {
      const minutes = Math.floor(time / 60)
      const seconds = time % 60
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    } else {
      return '00:00'
    }
  }

  const remainingTime = () => {
    setVerifyRemainingTime(15)
    setVerifyDisabled(true)

    let currentRemainingTime = 15
    let interval = setInterval(() => {
      setVerifyRemainingTime(currentRemainingTime--)
      if (currentRemainingTime < 0) {
        setVerifyDisabled(false)
        clearInterval(interval)
      }
    }, 1000)
  }

  const calculateDiscout = () => {
    if (transaction?.amount < transaction?.full_amount) {
      let calDiscount = transaction?.full_amount - transaction?.amount - (transaction?.coupon_value || 0)
      setDiscountAmount(calDiscount)
    }
  }

  const calculateSumAmount = () => {
    return transaction?.amount + transaction?.extend_dry
  }

  const onVerifyTransaction = async () => {
    setVerify(true)
    setSuccess(false)
    setVerifyLoading(true)
    try {
      if (calculateSumAmount() > 0) {
        const response = await verifyTransaction(token, paymentChannel, transaction?.transaction_ref, incExtendDry)
        if (response?.status === 'SUCCESS') {
          setSuccess(true)
        }
        throw response
      } else {
        const response = await freeTransaction(token, transaction?.transaction_ref)
        if (response?.message === 'success') {
          setSuccess(true)
        }
      }
    } catch (err) {
      // console.log("🚀 ~ createQRCodeData ~ err:", err)
    } finally {
      setVerifyLoading(false)
    }
  }

  const onCloseModal = () => {
    if (success) {
      setRefresh(true)
    }
    setShowModal(false)
    setQrcodeData(null)
    setDiscountAmount(0)
    setTimeLeft(300)
    setSuccess(false)
    setVerify(false)
    setVerifyRemainingTime(15)
    setVerifyDisabled(false)
    setPaymentChannel('')
  }

  const titleImage = type => {
    switch (type) {
      case 'promptpay':
        return ThaiQRPayment
      case 'truemoney':
        return TrueMoneyQRPayment
      case 'linepay':
        return LinePay
      default:
        return ''
    }
  }

  const htmlToImageConvert = () => {
    toPng(qrcodeRef.current, { cacheBust: false })
      .then(dataUrl => {
        const link = document.createElement('a')
        link.download = `${paymentChannel}.png`
        link.href = dataUrl
        document.body.appendChild(link);
        link.click()
        document.body.removeChild(link);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleDownloadImage = async () => {
    const element = qrcodeRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
        link.href = data;
        link.download = `codeclean-qr-payment.png`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(data);
    }
  }

  return (
    <div id="payment_modal" className="modal">
      <div className="modal-box max-w-4xl w-full p-0 rounded-none">
        <div className="w-full flex flex-col items-center">
          <PriceDetail
            incExtendDry={incExtendDry}
            discountAmount={discountAmount}
            transaction={transaction}
            sumAmount={calculateSumAmount()}
            timeLeft={formatTime(timeLeft)}
          />

          {/* <div className="w-full thai-qr-content flex items-center justify-center">  */}
          <div className="w-full flex items-center justify-center h-full my-8">
            <div ref={qrcodeRef}>
              <div className="mx-auto w-[274px] bg-gray-50 rounded-lg border">
                <div className="flex flex-col gap-6 items-center justify-center mb-6">
                  <div className="w-full h-[54px] bg-cyan-800 rounded-t-lg flex items-center justify-center">
                    <img src={titleImage(provider)} height={32} className="h-8" alt="qrcode-payment" />
                  </div>
                  {!verify ? (
                    <>
                      <div className="w-full flex items-center justify-center">
                        {imageLoading ? (
                          <span className="loading loading-spinner w-[156px] h-[156px]"></span>
                        ) : (
                          <>
                            {qrcodeData?.qrImage && (
                              <QrPayImage
                                src={qrcodeData?.qrImage}
                                width={156}
                                height={156}
                                alt="qrcode-payment"
                                type={provider.toUpperCase()}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div className="w-fit flex-col justify-center items-center gap-1 inline-flex">
                        <div className="text-center text-neutral-800 text-sm font-medium">สแกน QR เพื่อชำระเงิน</div>
                        <div className="text-center text-zinc-500 text-xs font-medium">
                          REF: {transaction?.transaction_ref}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full flex items-center justify-center">
                        {verifyLoading ? (
                          <span className="loading loading-spinner w-[156px] h-[156px] text-blue-700"></span>
                        ) : (
                          <>
                            {success ? (
                              <img src={PaymentSuccess} width={156} height={156} alt="payment-success" />
                            ) : (
                              <img src={PaymentFailed} width={156} height={156} alt="payment-failed" />
                            )}
                          </>
                        )}
                      </div>
                      {success ? (
                        <div className="w-fit flex-col justify-center items-center gap-1 inline-flex">
                          <div className="text-center text-success text-sm font-medium">ชำระเงินสำเร็จ</div>
                          <div className="text-center text-zinc-500 text-xs font-medium">
                            REF: {transaction?.transaction_ref}
                          </div>
                        </div>
                      ) : (
                        <div className="w-fit flex-col justify-center items-center gap-1 inline-flex">
                          <div className="text-center text-error text-sm font-medium">ชำระเงินไม่สำเร็จ</div>
                          <div className="text-center text-error text-sm font-medium">กรุณาติดต่อเจ้าหน้าที่</div>
                          <div className="text-center text-zinc-500 text-xs font-medium">
                            REF: {transaction?.transaction_ref}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {provider === 'promptpay' && (
                  <>
                    <div className="divider my-0 py-0 h-fit"></div>
                    <div className="w-full flex items-center justify-center p-1">
                      <img src={CodeCleanLogo} width={20} height={20} alt="codeclean-logo" />
                      &nbsp;&nbsp;
                      <div className="text-center text-zinc-500 text-[10px] font-normal tracking-tight">
                        Accepts all banks | ชำระเงินได้จากทุกธนาคาร
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <VerifyButtonGroup
            verify={verify}
            verifyDisabled={verifyDisabled}
            verifyRemainingTime={verifyRemainingTime}
            verifyLoading={verifyLoading}
            success={success}
            onVerifyTransaction={onVerifyTransaction}
            onCloseModal={onCloseModal}
            type={provider.toUpperCase()}
            saveToImage={htmlToImageConvert}
          />
        </div>
      </div>
    </div>
  )
}

export default ModalPayment
